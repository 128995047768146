import banner from "../../../Images/product.JPG";
import men from "../../../Images/F1BANNER.png";
import women from "../../../Images/FREDOM BANNER.png";
import mobileWoman from "../../../Images/freebanner.png";
import mobileMan from "../../../Images/banner.png";
let Category = [
  {
    id: "A",
    name: "WOMEN",
    banner: women,
    mobileBanner: mobileWoman,
    desc: "A description about women products",
  },
  {
    id: "B",
    name: "MEN",
    banner: men,
    mobileBanner: mobileMan,
    desc: "A description about men products",
  },
];
export default Category;
