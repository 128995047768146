import Footer from "../../Footer/Footer";
// import NavBar from "../../Header/Navbar";
import about from "../../../Images/aboutus.png";

const About = () => {
  return (
    <>
      {/* <NavBar /> */}
      <img className="w-full xl:w-3/5 mx-auto" src={about}></img>
      <div className="w-11/12 lg:w-3/5 mx-auto text-lg md:text-2xl p-5 mt-3 lg:mt-8 mb-12 text-justify">
        <p>
          Welcome to Mastershave, where precision meets performance on the
          cutting edge of shaving technology. Born from a passion for
          excellence, we engineer razors with the same precision and power you'd
          expect from race and rally engineering.
        </p>
        <p>
          At Mastershave, we're driven by innovation. Our Easy Glide technology
          ensures that each shave is as smooth as a perfectly executed turn on
          the track. With a razor designed for durability, we promise a
          performance that endures as long as your passion for the race.
        </p>
        <p>
          Our commitment to superior shave quality means each pass delivers a
          close, clean finish, like crossing the finish line in first place.
          Join us in the pursuit of shaving perfection. Mastershave—where every
          shave is a victory lap.
        </p>
      </div>
    </>
  );
};
export default About;
