import React from "react";
import { useState } from "react";
import "../../../App/App.css";
import bgzylone from "../../../../Images/ZYLONEHome.jpg";
import bginova from "../../../../Images/INOVABANNER.jpg";
import { useEffect } from "react";

const Parallaxbg = () => {
  const sliderImages = [bgzylone, bginova];
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % sliderImages.length);
    }, 4500);
    return () => clearInterval(intervalId);
  }, [sliderImages.length]);
  return (
    <>
      <div
        style={{ backgroundImage: `url(${sliderImages[currentSlide]})` }}
        className="marquee parallax z-0 "
      >
        <div className="bg-indigo-600 bg-opacity-0 text-black p-8 text-center absolute w-full px-auto py-[15%]">
          <p className="mt-10 text-xl sm:text-4xl lg:text-6xl font-semibold text-white italic md:mt-0  tracking-tight">
            A FRESH NEW SHAVE
          </p>
        </div>
      </div>
    </>
  );
};

export default Parallaxbg;
