import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../../../../styles.css";
import { Navigation } from "swiper/modules";
import ListData from "./ListData";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";

const Productlist = () => {
  return (
    <>
      <div className="mx-auto text-center mb-14">
        <p className="text-4xl font-semibold text-center my-5">Our Products</p>
        {ListData?.map((item) => {
          return (
            <div key={item.id} className="hidden xl:inline-block w-1/12">
              <Link to={`/products/${item.id}`}>
                <img
                  src={item.Image}
                  className="w-full transition ease-in-out hover:-translate-y-1 hover:scale-150 mt-6"
                ></img>
              </Link>
            </div>
          );
        })}
        <div className="xl:hidden block">
          <Swiper
            slidesPerView={5}
            spaceBetween={0}
            loop={true}
            breakpoints={{
              0: {
                slidesPerView: 2,
              },
              550: {
                slidesPerView: 3,
              },
              700: {
                slidesPerView: 3,
              },
            }}
            navigation={true}
            modules={[Navigation]}
            className="mySwiper text-white mx-auto "
            style={{
              "--swiper-navigation-color": "#acacac",
              "--swiper-navigation-size": "22px",
            }}
          >
            {ListData?.map((elem) => {
              return (
                <>
                  <SwiperSlide
                    key={elem.id}
                    className="p-4 inline-block xl:hidden"
                  >
                    <div className="w-full flex flex-col items-center text-center">
                      <Link to={`/products/${elem.id}`}>
                        <img
                          src={elem.Image}
                          className="md:w-4/5 w-full transition ease-in-out hover:-translate-y-1 hover:scale-110"
                        />
                      </Link>
                    </div>
                  </SwiperSlide>
                </>
              );
            })}
          </Swiper>
        </div>
      </div>
    </>
  );
};
export default Productlist;
