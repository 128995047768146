import f1front from "../../../../Images/f1front.png";
import freefront from "../../../../Images/freefront.png";
import r2front from "../../../../Images/r2FRONT.png";
import r3front from "../../../../Images/r3front.png";
import invfront from "../../../../Images/invfront.png";
import rpbluefront from "../../../../Images/rpblue-front.png";
import zyfront from "../../../../Images/zyfront.png";
import s2front from "../../../../Images/s2front.png";
import s3front from "../../../../Images/s3front.png";
import solo from "../../../../Images/soloFRONT.png";
import freelogo from "../../../../Images/logofreedom.png";
import invlogo from "../../../../Images/logoinova6.png";
import sense3logo from "../../../../Images/logosense3.png";
import sense2logo from "../../../../Images/logosense2.png";
import f1logo from "../../../../Images/logoformula1.png";
import race3logo from "../../../../Images/logorace3.png";
import rallyplogo from "../../../../Images/logoRally2plus.png";
import rally2logo from "../../../../Images/logorally2.png";
import zylonelogo from "../../../../Images/logozylon6.png";

let item = [
  {
    id: "6",
    name: "Formul1",
    logotype: f1logo,
    Image: f1front,
  },
  {
    id: "2",
    name: "FreeDom",
    logotype: freelogo,
    Image: freefront,
  },
  {
    id: "5",
    name: "Zylone",
    logotype: zylonelogo,
    Image: zyfront,
  },
  {
    id: "1",
    name: "Inova 6",
    logotype: invlogo,
    Image: invfront,
  },
  {
    id: "7",
    name: "Race 3",
    logotype: race3logo,
    Image: r3front,
  },
  {
    id: "3",
    name: "Sense 3",
    logotype: sense3logo,
    Image: s3front,
  },
  {
    id: "8",
    name: "Rally 2 Plus",
    logotype: rallyplogo,
    Image: rpbluefront,
  },
  {
    id: "4",
    name: "Sense 2",
    logotype: sense2logo,
    Image: s2front,
  },
  {
    id: "9",
    name: "Rally 2",
    logotype: rally2logo,
    Image: r2front,
  },
  {
    id: "10",
    name: "Solo 1",
    logotype: freelogo,
    Image: solo,
  },
];

export default item;
