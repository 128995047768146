import { useParams } from "react-router-dom";
import ProductData from "../ProductData";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "../../../../styles.css";
import { useState, useRef } from "react";

const PDetail = () => {
  const { producttId } = useParams();
  const thisProduct = ProductData.find((prod) => prod.id === producttId);
  const [selectedImage, setSelectedImage] = useState(thisProduct.coverimg);
  //   const [zoomPosition, setZoomPosition] = useState({ x: 0, y: 0 });
  //   const [isHovering, setIsHovering] = useState(false);
  //   const imageContainerRef = useRef(null);
  //   const zoomLensRef = useRef(null);
  //   const handleMouseMove = (e) => {
  //     const rect = imageContainerRef.current.getBoundingClientRect();
  //     const x = ((e.clientX - rect.left) / rect.width) * 100;
  //     const y = ((e.clientY - rect.top) / rect.height) * 100;

  //     setZoomPosition({ x, y });

  //     if (zoomLensRef.current) {
  //       zoomLensRef.current.style.backgroundPosition = `-${
  //         (x / 100) * rect.width * 2
  //       }px -${(y / 100) * rect.height * 2}px`;
  //     }
  //   };

  //   const handleMouseEnter = () => {
  //     setIsHovering(true);
  //   };

  //   const handleMouseLeave = () => {
  //     setIsHovering(false);
  //   };

  //   if (!thisProduct) {
  //     return <div>Product not found!</div>;
  //   }

  return (
    <>
      <div className="w-11/12 mx-auto my-4 hidden lg:grid grid-cols-3">
        <div className="lg:col-span-2 bg-gray-100 m-5 md:col-start-1 md:col-end-4 md:min-h-96">
          <img className=" p-11 mt-4 w-1/4" src={thisProduct.logotype}></img>
          <p className="text-2xl p-9 w-11/12 leading-8">{thisProduct.desc}</p>
        </div>
        <div className="w-full m-5">
          <div className="flex bg-gray-100">
            <img
              src={selectedImage}
              alt={thisProduct.title}
              className="w-4/5 mx-auto"
              //   ref={imageContainerRef}
              //   onMouseMove={handleMouseMove}
              //   onMouseEnter={handleMouseEnter}
              //   onMouseLeave={handleMouseLeave}
            />
            {/* {isHovering && (
              <div
                ref={zoomLensRef}
                className="absolute top-0 left-0 w-1/2 h-1/2 bg-no-repeat bg-cover border border-gray-300"
                style={{
                  backgroundImage: `url(${selectedImage})`,
                  backgroundSize: "200%",
                  transform: `translate(${zoomPosition.x}%, ${zoomPosition.y}%)`,
                }}
              ></div>
            )} */}
          </div>
          <div className="grid grid-cols-2 w-full mt-4 gap-5">
            {thisProduct.Image.map((image, index) => (
              <div
                className="w-full bg-gray-100 relative overflow-hidden max-h-[312px]"
                key={index}
                onClick={() => setSelectedImage(image)}
              >
                <img
                  src={image}
                  alt={`Product Image ${index + 1}`}
                  className="w-full h-full object-contain"
                ></img>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="lg:hidden grid w-full md:w-10/12 mx-auto my-7 p-0 md:p-5 pt-9">
        <Swiper
          style={
            {
              // "--swiper-navigation-color": "#fff",
              // "--swiper-pagination-color": "#fff",
            }
          }
          slidesPerView={2}
          loop={true}
          spaceBetween={10}
          pagination={{ clickable: true }}
          navigation={true}
          modules={[FreeMode, Navigation, Pagination]}
          className="mySwiper2 p-10 bg-gray-100"
        >
          {thisProduct.Image.map((image, index) => (
            <SwiperSlide className="px-6" key={index}>
              <img
                className="w-10/12 rounded-2xl pb-9"
                src={image}
                onClick={() => setSelectedImage(image)}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className=" bg-gray-100 my-6 p-5 md:min-h-96">
          <p className="text-xl p-2">{thisProduct.desc}</p>
        </div>
      </div>
    </>
  );
};
export default PDetail;
