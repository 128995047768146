import Logo from "../../Images/logo.png";
import { SlSocialInstagram } from "react-icons/sl";
import { TfiEmail } from "react-icons/tfi";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-3 bg-black md:h-32 text-white bottom-0 relative pb-3">
        <div className="pt-6 md:px-10 px-3">
          <Link to="/">
            <img
              className="mx-auto w-1/2 md:w-2/3 lg:w-1/2 xl:w-1/3"
              src={Logo}
              alt="Logo"
            />
          </Link>
        </div>
        <div className="pt-10 md:px-10 px-3 text-center">
          <p className="2xl:text-3xl lg:text-2xl  font-semibold">
            A FRESH NEW SHAVE
          </p>
          {/* <ul className="inline-flex">
            <li className="inline-block mx-2 md:mx-6">
              <Link>
                <SlSocialInstagram className="w-8 h-8" />
              </Link>
            </li>
            <li className="inline-block">
              <Link>
                <TfiEmail className="w-8 h-8" />
              </Link>
            </li>
          </ul> */}
        </div>
        <div className="pt-10 lg:px-10 px-3 float-right text-center text-base lg:text-lg">
          <p>©2024 MasterShave. All Right Reserve</p>
        </div>
      </div>
    </>
  );
};

export default Footer;
