import { useParams } from "react-router-dom";
import ProductData from "../ProductData";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "../../../../styles.css";
import { useState, useRef } from "react";

const ProductDetail = () => {
  const { productId } = useParams();
  const thisProduct = ProductData.find((prod) => prod.id === productId);
  const [zoomPosition, setZoomPosition] = useState({ x: 0, y: 0 });
  const [hoveredImageIndex, setHoveredImageIndex] = useState(null);
  const imageContainerRefs = useRef([]);
  console.log(thisProduct.Feature);
  const handleMouseMove = (e, index) => {
    const rect = imageContainerRefs.current[index].getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 100;
    const y = ((e.clientY - rect.top) / rect.height) * 100;
    setZoomPosition({ x, y });
  };

  const handleMouseEnter = (index) => {
    setHoveredImageIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredImageIndex(null);
  };

  if (!thisProduct) {
    return <div>Product not found!</div>;
  }
  const featureitems = thisProduct.Feature.map((feature) => (
    <li className="text-lg ">{feature}</li>
  ));
  return (
    <>
      <div className="w-11/12 mx-auto my-4 hidden xl:grid grid-cols-3">
        <div className="lg:col-span-2 bg-gray-100 m-5 md:col-start-1 md:col-end-4 md:min-h-96 p-5">
          <img
            className="2xl:p-10 xl:p-5 mt-4 w-1/4 h-1/3 inline-flex"
            src={thisProduct.logotype}
          ></img>
          <p className="2xl:text-2xl xl:text-xl pb-1 2xl:p-10 xl:p-5 w-3/4 leading-8 inline-flex text-justify">
            {thisProduct.desc}
          </p>
          <ul className="list-disc list-inside text-lg font-semibold text-black inline-flex p-5 justify-center w-full mb-5">
            <div className="grid-cols-1 grid gap-4 2xl:ml-0 ml-40">
              {featureitems}
            </div>
          </ul>
        </div>
        <div className="w-full m-5">
          <div className="grid grid-cols-2 w-full h-full gap-5">
            {thisProduct.Image.map((image, index) => (
              <div
                className="w-full bg-gray-100 relative overflow-hidden max-h-[312px]"
                key={index}
                ref={(el) => (imageContainerRefs.current[index] = el)}
                onMouseMove={(e) => handleMouseMove(e, index)}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                <img
                  src={image}
                  alt={`Product Image ${index + 1}`}
                  className="w-full h-full object-contain"
                ></img>
                {hoveredImageIndex === index && (
                  <div
                    className="absolute top-0 left-0 w-full h-full bg-no-repeat bg-cover border border-gray-300 bg-gray-100"
                    style={{
                      backgroundImage: `url(${image})`,
                      backgroundSize: "300%",
                      backgroundPosition: `${zoomPosition.x}% ${zoomPosition.y}%`,
                    }}
                  ></div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="xl:hidden grid w-full md:w-10/12 mx-auto mb-7 p-0 md:p-5 ">
        <Swiper
          slidesPerView={1}
          loop={true}
          spaceBetween={10}
          pagination={{ clickable: true }}
          navigation={true}
          modules={[FreeMode, Navigation, Pagination]}
          className="mySwiper2 p-10 bg-gray-100"
          style={{
            "--swiper-navigation-color": "#acacac",
            "--swiper-navigation-size": "22px",
          }}
        >
          {thisProduct.Image.map((image, index) => (
            <SwiperSlide className="px-6" key={index}>
              <img className="w-full rounded-2xl pb-9" src={image} />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className=" bg-gray-100 my-6 p-5 md:min-h-96">
          <img className="md:w-1/3 w-1/2 p-2" src={thisProduct.logotype}></img>
          <p className="md:text-xl text-lg p-2">{thisProduct.desc}</p>
          <ul className="list-disc list-inside text-lg font-semibold text-black inline-flex p-5 justify-start w-full">
            <div className="grid-cols-1 grid gap-4 text-sm md:font-semibold font-medium">
              {featureitems}
            </div>
          </ul>
        </div>
      </div>
    </>
  );
};
export default ProductDetail;
