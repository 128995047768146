import React, { useEffect, useRef, useState } from "react";
import { Transition } from "@headlessui/react";
import Logo from "../../Images/logo.png";
import { Link } from "react-router-dom";
import { IoChevronDownOutline } from "react-icons/io5";
import f1 from "../../Images/f1bag.png";
import zylon from "../../Images/zylonbag.png";
import mrace3 from "../../Images/race1bag.png";
import mrally from "../../Images/rallybag.png";
import mrallyplus from "../../Images/rallyplusbag.png";
import wfree from "../../Images/freebag.png";
import winova from "../../Images/inovabag.png";
import wsense2 from "../../Images/sense2pack.png";
import wsense3 from "../../Images/sense3bag.png";
import Category from "../Pages/Products/Categorydata";
import ProductData from "../Pages/Products/ProductData";

const NavBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isWomenOpen, setIsWomenOpen] = useState(false);
  const [isMenOpen, setIsMenOpen] = useState(false);
  const [activeItems, setActiveItems] = useState([]);

  const womenMenuRef = useRef(null);
  const menMenuRef = useRef(null);

  const toggleWomen = () => {
    setIsWomenOpen(!isWomenOpen);
    if (!isWomenOpen) {
      setIsMenOpen(false);
      setActiveItems(women);
    }
  };

  const toggleMen = () => {
    setIsMenOpen(!isMenOpen);
    if (!isMenOpen) {
      setIsWomenOpen(false);
      setActiveItems(men);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        womenMenuRef.current &&
        !womenMenuRef.current.contains(event.target) &&
        menMenuRef.current &&
        !menMenuRef.current.contains(event.target)
      ) {
        setIsWomenOpen(false);
        setIsMenOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [womenMenuRef, menMenuRef]);

  let women = [
    {
      id: 1,
      image: winova,
      title: "INOVA6",
    },
    {
      id: 2,
      image: wfree,
      title: "FREEDOM",
    },

    {
      id: 3,
      image: wsense3,
      title: "SENSE3",
    },
    {
      id: 4,
      image: wsense2,
      title: "SENSE2",
    },
  ];
  const men = [
    {
      id: 6,
      image: f1,
      title: "Formula1",
    },
    {
      id: 5,
      image: zylon,
      title: "ZYLONE",
    },
    {
      id: 7,
      image: mrace3,
      title: "RACE3",
    },
    {
      id: 8,
      image: mrallyplus,
      title: "RALLY2PLUS",
    },
    {
      id: 9,
      image: mrally,
      title: "RALLY2",
    },
  ];
  return (
    <nav className="bg-black text-white w-full sticky top-0 h-[80px] z-[100]">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-row items-center justify-between h-16">
          <div className="basis-1/2 md:basis-1/4 items-center">
            <div className="flex-shrink-0">
              <Link to="/">
                <img className="w-full lg:w-2/3" src={Logo} alt="Logo" />
              </Link>
            </div>
          </div>
          <div className="hidden md:flex md:basis-3/4 space-x-4 justify-end">
            <div className="relative" ref={womenMenuRef}>
              <Link
                to="/products"
                className="px-3 py-2 rounded-md text-lg font-semibold focus:outline-none inline-block"
              >
                Women
              </Link>
              <IoChevronDownOutline
                className="inline-block cursor-pointer"
                onClick={toggleWomen}
              />

              <Transition
                show={isWomenOpen}
                enter="transition ease-out duration-100 transform"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-in duration-75 transform"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="fixed lg:top-12 z-10 md:left-1/2 lg:left-1/2 transform -translate-x-1/2 mt-6  w-screen max-w-6xl min-w-full bg-black rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="py-1 flex justify-center">
                    <div className="p-4 flex flex-wrap justify-center">
                      {activeItems.map((item) => (
                        <Link
                          to={`/products/${item.id}`}
                          key={item.id}
                          className="group relative inline-block sm:w-1/5 w-full rounded-lg p-4 text-sm leading-6 hover:bg-[#131f33] text-center"
                        >
                          <img
                            src={item.image}
                            className="w-full lg:w-3/5 inline-block mb-2"
                          />
                          <p className="block">{item.title}</p>
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
              </Transition>
            </div>
            <div className="relative" ref={menMenuRef}>
              <Link
                to="/products"
                className="px-3 inline-block py-2 rounded-md text-lg font-semibold focus:outline-none"
              >
                Men
              </Link>
              <IoChevronDownOutline
                className="inline-block cursor-pointer"
                onClick={toggleMen}
              />
              <Transition
                show={isMenOpen}
                enter="transition ease-out duration-100 transform"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-in duration-75 transform"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="fixed lg:top-12 z-10 md:left-1/2 lg:left-1/2 transform -translate-x-1/2 mt-6  w-screen max-w-6xl min-w-full bg-black rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="py-1 flex justify-center">
                    <div className="p-4 flex flex-wrap justify-center">
                      {activeItems.map((elem) => (
                        <Link
                          to={`/products/${elem.id}`}
                          key={elem.id}
                          className="group relative inline-block sm:w-1/5 w-full rounded-lg p-4 text-sm leading-6 hover:bg-[#131f33] text-center"
                        >
                          <img
                            src={elem.image}
                            className="w-full lg:w-3/5 inline-block mb-2"
                          />
                          <p className="block">{elem.title}</p>
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
              </Transition>
            </div>
            <Link
              to="/about"
              className="px-3 py-2 rounded-md text-lg font-semibold"
            >
              About Us
            </Link>
          </div>
          <div className="-mr-2 flex md:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="bg-gray-900 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white"
            >
              <span className="sr-only">Open main menu</span>
              {isMenuOpen ? (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>

      <Transition
        show={isMenuOpen}
        enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        {(ref) => (
          <div className="md:hidden bg-black z-40" id="mobile-menu">
            <div ref={ref} className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              <Link
                to="/about"
                className="block px-3 py-2 rounded-md text-base font-medium"
              >
                About Us
              </Link>
              <Link to="/products">
                <button
                  className="block px-3 py-2 rounded-md text-base font-medium focus:outline-none"
                  // onClick={toggleWomen}
                >
                  Women
                  {/* <IoChevronDownOutline className="inline-block" /> */}
                </button>
              </Link>
              {/* {isWomenOpen && (
                <div className="ml-4 space-y-1">
                  {women.map((item) => (
                    <div key={item.id}>
                      <Link to={`/products/${item.id}`}>
                        <a className="block px-3 py-2 rounded-md text-base font-medium">
                          {item.title}
                        </a>
                      </Link>
                    </div>
                  ))}
                </div>
              )} */}
              <Link to="/products">
                <button
                  className="block px-3 py-2 rounded-md text-base font-medium focus:outline-none"
                  // onClick={toggleMen}
                >
                  Men
                  {/* <IoChevronDownOutline className="inline-block" /> */}
                </button>
              </Link>
              {/* {isMenOpen && (
                <div className="ml-4 space-y-1">
                  {men.map((item) => (
                    <div key={item.id}>
                      <Link to={`/products/${item.id}`}>
                        <p className="block px-3 py-2 rounded-md text-base font-medium">
                          {item.title}
                        </p>
                      </Link>
                    </div>
                  ))}
                </div>
              )} */}
            </div>
          </div>
        )}
      </Transition>
    </nav>
  );
};

export default NavBar;
