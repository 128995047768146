import Footer from "../../Footer/Footer";
// import NavBar from "../../Header/Navbar";
import banner from "../../../Images/product.JPG";
import ProductData from "./ProductData";
import Category from "./Categorydata";
import { Link } from "react-router-dom";

const Products = () => {
  return (
    <>
      {/* <NavBar /> */}
      {Category?.map((item) => {
        return (
          <div key={item.id} className="mb-20 2xl:min-h-[51em] xl:min-h-[39em]">
            <div className="mb-10" id={item.id}>
              <img
                className="w-full hidden lg:block selection:"
                src={item.banner}
              ></img>
              <img
                className="w-full block lg:hidden selection:"
                src={item.mobileBanner}
              ></img>
            </div>
            <div className="w-full sm:w-4/5 mx-auto text-center">
              <p className="text-4xl font-semibold block">{item.name}</p>
              {/* <p className="text-lg font-normal">{item.desc} </p> */}

              <div
                className={
                  "grid " +
                  (item.id === "A" ? "xl:grid-cols-4" : "xl:grid-cols-6") +
                  " grid-cols-2 md:grid-cols-4 mx-auto text-center my-8 p-4 rounded-lg gap-8 lg:gap-5"
                }
              >
                {ProductData?.map((elem, index) => {
                  return elem.categoryId === item.id ? (
                    <div className="mx-auto" key={index}>
                      <Link
                        className="grid justify-items-center"
                        to={`/products/${elem.id}`}
                      >
                        <img
                          src={elem.coverimg}
                          className={
                            "block w-full " +
                            (item.id === "A" ? "xl:w-[65%]" : "lg:w-full") +
                            " transition ease-in-out hover:-translate-y-1 hover:scale-150 hover:mb-10"
                          }
                        ></img>
                        <p className="text-[14px] tracking-normal xl:text-base font-semibold block text-center my-4 rounded-[5px] px-4 lg:px-8 py-1">
                          {elem.title}
                        </p>
                      </Link>
                    </div>
                  ) : (
                    <></>
                  );
                })}
              </div>
              {/* <div className="flex flex-row mx-auto text-center my-8">
                {ProductData?.map((elem, index) => {
                  return elem.categoryId === item.id ? (
                    <div className="basis-1/3" key={index}>
                      <Link
                        className="grid justify-items-center"
                        to={`/products/${elem.id}`}
                      >
                        <img src={elem.coverimg} className="block w-3/5 "></img>
                        <p className="text-base font-semibold block text-center my-4">
                          {elem.title}
                        </p>
                      </Link>
                    </div>
                  ) : (
                    <></>
                  );
                })}
              </div> */}
            </div>
          </div>
        );
      })}
    </>
  );
};
export default Products;
