import icon1 from "../../../../Images/Artboard1.png";
import icon2 from "../../../../Images/Artboard2.png";
import icon3 from "../../../../Images/Artboard3.png";
const Property = () => {
  return (
    <>
      <div className="grid md:grid-cols-3 grid-cols-1 my-10 md:my-20 text-center">
        <div className="px-10 pb-10 md:p-12 text-center">
          <img src={icon1} alt="icon1" className="inline-grid" />
          <div className="text-center">
            <p className="md:textlg lg:text-xl font-semibold">Easy Glide</p>
            <p className="md:text-sm lg:text-lg">
              Experience effortless shaving with our Easy Glide technology,
              ensuring smooth and comfortable every time.
            </p>
          </div>
        </div>
        <div className="px-10 pb-10 md:p-12 text-center">
          <img src={icon2} alt="icon2" className="inline-grid" />
          <div className="text-center">
            <p className="md:textlg lg:text-xl font-semibold">Durable</p>
            <p className="md:text-sm lg:text-lg">
              Built to last, our razor's durable design guarantees long-lasting
              performance and dependable results.
            </p>
          </div>
        </div>
        <div className="px-10 pb-5 md:p-12 text-center">
          <img src={icon3} alt="icon3" className="inline-grid" />
          <div className="text-center">
            <p className="md:textlg lg:text-xl font-semibold">Shave Quality</p>
            <p className="md:text-sm lg:text-lg">
              Enjoy superior shave quality with precision blades that deliver a
              close, irritation-free finish.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Property;
