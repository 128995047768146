import React from "react";
import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom";
import About from "../Pages/About/About";
import Home from "../Pages/Home";
import Products from "../Pages/Products/Products";
import ProductDetail from "../Pages/Products/Detail/ProductDetail";
import NavBar from "../Header/Navbar";
import Footer from "../Footer/Footer";
import PDetail from "../Pages/Products/Detail/PDetail";
function App() {
  return (
    <>
      {/* <BrowserRouter basename="/">
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/products" element={<Products />} />
          <Route path="/products/:productId" element={<ProductDetail />} />
        </Routes>
      </BrowserRouter> */}
      <HashRouter>
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/products" element={<Products />} />
          <Route path="/products/:productId" element={<ProductDetail />} />
          <Route path="/products/Detail/:producttId" element={<PDetail />} />
        </Routes>
        <Footer />
      </HashRouter>
    </>
  );
}

export default App;
