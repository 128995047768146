import imgf1 from "../../../../Images/fhead.jpg";
import imgf2 from "../../../../Images/fbody.jpg";
import imgf3 from "../../../../Images/fbody2.jpg";
import imgf4 from "../../../../Images/fhead2.jpg";
import imgfr1 from "../../../../Images/FREDOMHEAD.jpg";
import imgfr2 from "../../../../Images/FREDOMBODY.jpg";
import imgfr3 from "../../../../Images/FREDONBODY2.jpg";
import imgfr4 from "../../../../Images/FREDOMHEAD2.jpg";
import "swiper/css";
import "swiper/css/effect-fade";
import "../../../../styles.css";
import { Autoplay, EffectFade } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const Gallery = () => {
  return (
    <>
      <Swiper
        spaceBetween={30}
        effect={"fade"}
        centeredSlides={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, EffectFade]}
        className="gallerySwiper"
        id="autoSwiper"
      >
        <SwiperSlide>
          <div className="lg:w-8/12 w-10/12 mx-auto">
            <div className="flex flex-row my-5 gap-x-4 md:gap-x-7">
              <div className="basis-3/4 ">
                <img
                  src={imgf2}
                  alt="gallery1"
                  className="rounded-xl w-full md:rounded-3xl"
                />
              </div>
              <div className="basis-[23%] rounded-3xl">
                <img
                  src={imgf1}
                  alt="gallery4"
                  className="rounded-xl md:rounded-3xl w-full"
                />
              </div>
            </div>
            <div className="flex flex-row my-5 gap-x-4 md:gap-x-7">
              <div className="basis-[23%] rounded-3xl ">
                <img
                  src={imgf4}
                  alt="gallery2"
                  className="rounded-xl md:rounded-3xl w-full"
                />
              </div>
              <div className="basis-3/4">
                <img
                  src={imgf3}
                  alt="gallery3"
                  className="rounded-xl w-full md:rounded-3xl"
                />
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="lg:w-8/12 w-10/12 mx-auto">
            <div className="flex flex-row my-5 gap-x-4 md:gap-x-7">
              <div className="basis-3/4 ">
                <img
                  src={imgfr3}
                  alt="gallery1"
                  className="rounded-xl w-full md:rounded-3xl"
                />
              </div>
              <div className="basis-[23%] rounded-3xl">
                <img
                  src={imgfr1}
                  alt="gallery4"
                  className="rounded-xl md:rounded-3xl w-full"
                />
              </div>
            </div>
            <div className="flex flex-row my-5 gap-x-4 md:gap-x-7">
              <div className="basis-[23%] rounded-3xl mb-5">
                <img
                  src={imgfr4}
                  alt="gallery2"
                  className="rounded-xl md:rounded-3xl w-full"
                />
              </div>
              <div className="basis-3/4">
                <img
                  src={imgfr2}
                  alt="gallery3"
                  className="rounded-xl w-full md:rounded-3xl"
                />
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>

      {/* <div className="lg:w-8/12 w-10/12 mx-auto">
        <div className="flex flex-row my-5 gap-x-4 md:gap-x-7">
          <div className="basis-3/4 ">
            <img
              src={imgfr2}
              alt="gallery1"
              className="rounded-xl w-full md:rounded-3xl"
            />
          </div>
          <div className="basis-[23%] rounded-3xl">
            <img
              src={imgfr1}
              alt="gallery4"
              className="rounded-xl md:rounded-3xl w-full"
            />
          </div>
        </div>
        <div className="flex flex-row my-5 gap-x-4 md:gap-x-7">
          <div className="basis-[23%] rounded-3xl mb-5">
            <img
              src={imgfr4}
              alt="gallery2"
              className="rounded-xl md:rounded-3xl w-full"
            />
          </div>
          <div className="basis-3/4">
            <img
              src={imgfr3}
              alt="gallery3"
              className="rounded-xl w-full md:rounded-3xl"
            />
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Gallery;
